import React from 'react'

interface IProps {
  phone: string
  className?: string
}

const PhoneLink: React.FC<IProps> = ({
  phone,
  className = 'has-text-white has-text-weight-normal',
}) =>
  phone ? (
    <a
      href={`tel:${phone.replace(/[^+\d]/g, '')}`}
      className={className}
      title={phone}
    >
      {phone}
    </a>
  ) : null

export default PhoneLink
