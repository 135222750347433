import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'

import Card from '../components/Feedback/Card'
import Button from '../components/button/button'
import ContactForm from '../components/contact-form'
import { isBrowser } from '../utils'
import { GlobalContext } from '../context/GlobalContext'
import PhoneLink from '../components/phone-link'
import { IFeedback } from '../components/Feedback/types'

import LogoBase from '../svg/logo-base'

import './meetup.styles.scss'

const feedbacks: IFeedback[] = [
  {
    photoId: 'author1',
    name: 'Александр Попов',
    position: 'Руководитель разработки SIGNAL.',
    text: 'BIM-лидер, автор телеграм канала «Просто о BIM» и YouTube\u2011канала Александр Попов BIM.\n Преподаватель по Revit, Dynamo, Navisworks. Эксперт по BIM 360 Docs (ACC Docs), Forge.\n В прошлом руководитель разработок в ПИК (Робот R1, R2) и в ФСК\u2011Лидер, BIM\u2011менеджер в AECOM и Werfau.\n По образованию инженер\u2011конструктор САПР ПГС.',
  },
  {
    photoId: 'author2',
    name: 'Александр Осипов',
    position: 'Генеральный директор\n ООО «Академия БИМ»',
    text: 'Начиная с 1999 г. Александр занимается внедрением BIM\u2011технологий в процесс проектирования в компаниях различного профиля.\n Постоянный член клуба BIM Лидеров России.\n Член Экспертного совета при Министерстве строительства и ЖКХ РФ по вопросу поэтапного внедрения BIM\u2011технологий в области промышленного и гражданского строительства.',
  },
  {
    photoId: 'author3',
    name: 'Алексей Савватеев',
    position: 'Руководитель BIM-отдела Айбим',
    text: 'Руководитель BIM\u2011отдела ООО «Айбим». Эксперт по внедрению технологии BIM в проектные и девелоперские организации, более 20 успешно реализованных проектов. Сертифицированный инструктор Autodesk. С 2011 года занимался продвижением Autodesk Revit в проектные организации, с 2017 — цифровизацией девелоперов и промышленных компаний.',
  },
  {
    photoId: 'author4',
    name: 'Роман Митин',
    position: 'Директор по продукту IYNO',
    text: 'Более 15лет работы в области автоматизации, алгоритмизации и цифровизации проектирования и строительства, один из первых экспертов, получивший статус BIM Лидера в России. Развивая IYNO, использует весь опыт работы, накопленный непосредственно на строительной площадке.',
  },
]

const plans = [
  {
    time: '9:00 – 10:00',
    text: 'Сбор гостей. Кофе-брейк',
  },
  {
    time: '10:00 – 10:15',
    text: `Александр Осипов. ООО «Академия BIM».\n Обзор текущей ситуации с BIM в РФ`,
  },
  {
    time: '10:15 – 10:45',
    text: 'Александр Попов.\n BIM-методология SIGNAL',
  },
  {
    time: '10:45 – 11:00',
    text: 'Алексей Савватеев. ООО «Айбим».\n Реальные кейсы применения BIM в девелоперской организации',
  },
  {
    time: '11:00 – 11:20',
    text: 'Александр Попов.\n Новинки и анонсы SIGNAL',
  },
  {
    time: '11:20 – 11:50',
    text: 'BIM-баттл: Ведомость объемов работ по BIM-модели\n SIGNAL: Александр Попов\n IYNO: Роман Митин',
  },
  {
    time: '11:50 – 12:00',
    text: 'Завершение мероприятия',
  },
]
const MeetupPage: React.FC = () => {
  const [isOpenModal, setIsOpenModal] = React.useState(false)
  const { location } = React.useContext(GlobalContext)
  const locationState = location?.state

  React.useEffect(() => {
    if (isBrowser()) {
      if (locationState?.tryModalOpened) {
        setIsOpenModal(true)
        locationState.tryModalOpened = false
      }
    }
  }, [locationState])

  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <div className="meetup">
        <div className="meetup_header">
          <GatsbyImage
            className="meetup_header_image"
            image={images.meetup.childImageSharp.gatsbyImageData}
            alt="Meetup"
            title="Meetup"
          />
          <GatsbyImage
            className="meetup_header_image"
            image={images.ray.childImageSharp.gatsbyImageData}
            alt="Луч"
            title="Луч"
          />
          <section className="meetup_section">
            <div className="meetup_container">
              <div className="meetup_header_content">
                <LogoBase className="meetup_logo" />
                <h1 className="meetup_title">
                  {`Приглашаем вас\n на `}
                  <span className="has-text-primary">BIM-завтрак</span>
                  {` с SIGNAL`}
                </h1>
              </div>
            </div>
          </section>
        </div>
        <section className="meetup_section">
          <div className="meetup_container">
            <p>{`Профессиональное офлайн\u2011мероприятие в сфере цифрового строительства\n BIM\u2011завтрак с SIGNАL – это:`}</p>
            <ul className="meetup_list">
              <li>
                Практический опыт реализации BIM в условиях современной
                реальности.
              </li>
              <li>BIM-баттл между вендорами: SIGNAL vs IYNO</li>
              <li>Влияние SIGNAL на цифровизацию строительного рынка.</li>
              <li>Насыщенная дискуссионная программа.</li>
              <li>Ответы профессионалов отрасли на наболевшие вопросы.</li>
              <li>
                Возможность поддержать старые или установить новые деловые
                контакты.
              </li>
            </ul>
            <p>
              {`Приглашается профсообщество: BIM\u2011лидеры, руководители департаментов цифровизации,\n BIM\u2011менеджеры, BIM\u2011интеграторы, цифровые инженеры.`}
            </p>
          </div>
        </section>
        <section className="meetup_section">
          <div className="meetup_container meetup_speaker">
            <div className="meetup_content">
              <h2 className="meetup_subtitle">Спикеры</h2>
              <Swiper
                wrapperClass="meetup_swiper"
                className="meetup_swiper_wrapper"
                freeMode={true}
                navigation={true}
                breakpoints={{
                  0: {
                    slidesPerView: 'auto',
                  },
                  1480: {
                    slidesPerView: 4,
                  },
                }}
                pagination={{
                  clickable: true,
                  bulletClass: 'swiper_pagination pagination',
                  bulletActiveClass: 'active',
                }}
                modules={[Pagination, Navigation]}
              >
                {feedbacks.map(feedback => (
                  <SwiperSlide className="meetup_slide" key={feedback.name}>
                    <div className="meetup_slide_content">
                      <Card
                        image={
                          feedback.photoId &&
                          images[feedback.photoId].childImageSharp
                            .gatsbyImageData
                        }
                        name={feedback.name}
                        position={feedback.position}
                        text={feedback.text}
                        className="meetup_card"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
        <section className="meetup_section">
          <div className="meetup_container">
            <div className="meetup_content">
              <h2 className="meetup_subtitle">План</h2>
              {plans.map(plan => (
                <div className="meetup_plan" key={plan.time}>
                  <span style={{ width: '156px' }} className="has-text-primary">
                    {plan.time}
                  </span>
                  <span>{plan.text}</span>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="meetup_section">
          <div className="meetup_container">
            <div className="meetup_content">
              <h2 className="meetup_subtitle">Место и время</h2>
              <p style={{ color: '#DCDCDC' }}>Мероприятие состоится:</p>
              <p>
                16 марта 2023, г. Москва, ГБУ Мосстройинформ (Дом на Брестской),
                ул. 2-я Брестская, д.6
              </p>
              <p style={{ color: '#DCDCDC' }} className="mt-2">
                Контактное лицо:
              </p>
              <p>Сергей Казанцев</p>
              <PhoneLink
                phone="+7 (921) 552-32-55"
                className="has-text-white"
              />
            </div>
          </div>
        </section>
        <section className="meetup_section">
          <div className="meetup_container">
            <div className="meetup_content">
              <h2 className="meetup_subtitle">Регистрация</h2>
              <p>Количество мест ограничено!</p>
              <p>Требуется предварительная регистрация. Участие бесплатное.</p>
              <div className="meetup_button_wrap">
                <Button
                  size="medium"
                  onClick={() => setIsOpenModal(true)}
                  className="meetup_button"
                >
                  Зарегистрироваться
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
      {isOpenModal && (
        <ContactForm
          onClose={() => setIsOpenModal(false)}
          title="Регистрация на BIM-завтрак с SIGNAL 16.03.2023"
          requestType="BIM breakfast"
        />
      )}
    </>
  )
}

export default MeetupPage

const imagesQuery = graphql`
  query {
    meetup: file(relativePath: { eq: "meetup.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    ray: file(relativePath: { eq: "ray.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    author1: file(relativePath: { eq: "author/Alexander_Popov.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 95, placeholder: NONE)
      }
    }
    author2: file(relativePath: { eq: "author/Alexander_Osipov.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 95, placeholder: NONE)
      }
    }
    author3: file(relativePath: { eq: "author/Alexey_Savvateev.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 95, placeholder: NONE)
      }
    }
    author4: file(relativePath: { eq: "author/Roman_Mitin.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 95, placeholder: NONE)
      }
    }
  }
`
